import { Agence } from '@app/agence/agence.model';
import { FilialeConvention } from '@app/filiale/filiale.model';
import { GroupeConditions } from './groupe-conditions.model';
import { Marque } from '@app/marque/marque.model';
import { Partenariat } from '@app/partenariat/partenariat.model';
import { ParagrapheConvention } from '@app/convention/paragraphe-convention.model';
import { Prestation } from '@app/convention/prestation.model';
import { SelectionMois } from '@app/components/mois-selector';
import { Segment } from '@app/convention/segment.model';
import { SignataireConvention } from '@app/signature/signataire-convention.model';
import { Signature } from '@app/signature/signature.model';
import { TcoType } from '@app/convention/type-convention.model';

import { Reglement } from '@app/compta/reglement.model';

import { startOfMonth, endOfMonth, ExtensibleObject } from '@helpers/utils';

export class Convention extends ExtensibleObject {
	con_id: number;
	ctx_id: number;
	ctx_libelle: string;
	tco_type: TcoType;
	con_date_debut: Date|string;
	con_date_fin: Date|string;
	con_date_creation: Date|string;
	con_date_modification: Date|string;
	con_date_fusion_word?: Date|string;
	msc_code: string = 'A'; // A: saisie par agence
	per_code_acomptes: string = 'A'; // A: periodicité annuelle
	trg_code: string = 'F'; // F: Facture
	etc_code: string = 'R'; // R: en cours de rédaction
	con_actif: boolean = true;
	con_precisions_perimetre: string;
	pays: any[] = [];

	con_factures_acomptes: boolean = false;
	con_mois_acomptes: SelectionMois;
	con_acompte_annuel_prestation: number;
	con_acompte_annuel_bfa: number;

	uti_responsable_nego: number;
	con_validation_sms_resp_nego: boolean = false;
	signataires_conventions: SignataireConvention[] = [];
	sco_signataire_principal: number|null;
	responsable_nego?: any;
	signatures: Signature[] = [];

	prestations: Prestation[] = [];

	marques_conventions: Marque[] = [];
	filiales_conventions: FilialeConvention[] = [];
	partenariat: Partenariat;

	segments: Segment[] = [];
	segments_standards: Segment[];
	challenges: Segment[];

	groupes_conditions: GroupeConditions[];

	agences_conventions: Agence[];

	paragraphes_conventions: ParagrapheConvention[];

	label?: string;
	label_short?: string;

	reglements: Reglement[];

	constructor(tco_type?: TcoType, ctx_id?: number) {
		super();
		if (tco_type) this.tco_type = tco_type;
		if (ctx_id) this.ctx_id = ctx_id;
		this.segments.push(new Segment('S', true, false));
		this.segments.push(new Segment('S', false, true));
		const today = new Date();
		this.con_date_debut = startOfMonth(new Date(today.getFullYear(), 0, 1));
		this.con_date_fin = endOfMonth(new Date(today.getFullYear(), 11, 31));
	}
}
