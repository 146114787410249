import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TargetState } from '@uirouter/core';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';

import { AuthService } from '@app/auth/auth.service';
import { StateUtilsService } from '@helpers/state-utils.service';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'login',
	templateUrl: 'login.html',
})
export class LoginComponent implements OnInit, IEventListener {

	@Input() returnTo: TargetState;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	loginForm: FormGroup;
	loading = false;
	submitted = false;
	error: string = '';

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private eventManager: EventManagerService,
		private stateUtils: StateUtilsService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.loading = false;
			}
		);
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			remember_me: [false]
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
	}

	// convenience getter for easy access to form fields
	get f() { return this.loginForm.controls; }


	onSubmit(): void {
		this.submitted = true;
		if (this.loginForm.invalid) return;
		this.loading = true;
		this.error = '';
		this.authService.login(this.f.email.value, this.f.password.value, this.f.remember_me.value)
		.subscribe({
			next: (response: any) => {
				this.stateUtils.returnToState(this.returnTo);
			},
			error: (httpResponse: any) => {
				this.loading = false;
				console.log('login error httpResponse', httpResponse);
				if (!!!httpResponse) return;
				if (httpResponse.error && httpResponse.error.data && httpResponse.error.data.essai) {
					if (httpResponse.error.data.bloque) {
						this.error = `Ce compte est bloqué suite à de multiples tentatives de connexion échouées. Veuillez contacter un administrateur.`;
					}
					else {
						this.error = `${httpResponse.error.data.essai} tentative(s) sur ${httpResponse.error.data.max}`;
					}
				}
				else if (httpResponse.error.message) {
					this.error = httpResponse.error.message;
				}
				else {
					this.error = httpResponse.error;
				}
			}
		})
		// Special case, don't stop the loading status on success
		// there's some resolves and stuff loading happening after the login,
		// it's better to stay with a loding status,
		// otherwise it's misleading because the view doesn't change until everything's loaded
		// .add(() => { this.loading = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		CheckboxModule,
		FormsModule,
		ReactiveFormsModule,
		UIRouterModule,
		ButtonModule,
		InputTextModule,
		MessageModule,
		ProgressBarModule,
	],
	exports: [LoginComponent],
	declarations: [LoginComponent]
})
export class LoginModule { }
