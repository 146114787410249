import { Societe } from '@app/societe/societe.model';

export class Contexte {
	ctx_id: number;
	grc_id: number;
	por_id: number;
	ctx_libelle: string;
	grc_libelle?: string;
	ctx_description: string;
	ctx_logo_print: string;
	ctx_logo_listing: string;
	ctx_trombinoscope: string;
	ctx_actif: boolean;
	ctx_date_referencement: string; // date
	soc_id?: number;
	societe: Societe;
	groupe_contexte?: any;
	cic_code?: string;
	cig_code?: string;
}
