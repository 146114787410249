import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';

@Pipe({name: 'conventionNicename'})
export class ConventionNicenamePipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(convention: string, short: boolean): string {
		if (short) return this.conventionService.getConventionNicename(convention, false);
		return this.conventionService.getConventionNicename(convention, true);
	}
}
