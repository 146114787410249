import { TransitionService, Transition, StateObject } from '@uirouter/core';
import { Observable } from 'rxjs';

import { AppMenuService } from '@global/app-menu.service';
import { AuthService } from '@app/auth/auth.service';
import { EventManagerService } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';
import { PortailService } from '@app/portail/portail.service';
import { StorageService } from '@global/storage.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { StateUtilsService } from '@helpers/state-utils.service';

function successOrRedirect(
	apiCall: Observable<any>,
	transition: Transition,
	ignoreStatusCodes: number[] = [],
	defaultError?: any
) {
	const stateService = transition.router.stateService;
	return apiCall.toPromise()
	.then(
		(response: any) => {
			return response;
		},
		(error: any) => {
			if (ignoreStatusCodes.includes(error.status)) {
				return false; // TODO false ou bien l'erreur ?
			}
			if (error.error && error.error.data && error.error.data.error_code) {
				return stateService.target('service_unavailable', {error: error.error.data.error_code});
			}
			if (error.error && error.error.error_code) {
				return stateService.target('service_unavailable', {error: error.error.error_code});
			}
			else if (defaultError) {
				return stateService.target('service_unavailable', defaultError);
			}
			else {
				return stateService.target('service_unavailable', {error: 'unknown'});
			}
		}
	);
}

function successOrLogout(apiCall: Observable<any>, transition: Transition) {
	const eventManager: EventManagerService = transition.injector().get(EventManagerService);
	return apiCall.toPromise()
	.then(
		(response: any) => {
			return response;
		},
		(error: any) => {
			eventManager.emit('logout');
		}
	);
}

// In a guard, we can wait for resolves to complete. Eg.:
// let tokens = transition.getResolveTokens();
// let promises = tokens.map((token: any) => {
// 	console.log('token', token)
// 	// transition.injector().getAsync(token)
// });
// Promise.all(promises).then((values: any) => console.log("Resolved values: " + values));

// hook to force the fetching of the application params
export function parametresGuardHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.parametresGuard; }
	};
	const fetchParams = (transition: Transition) => {
		const paramService: ParametreService = transition.injector().get(ParametreService);
		const stateService = transition.router.stateService;
		if (!paramService.serverParams) {
			return successOrRedirect(paramService.getParametres(), transition);
		}
		return true;
	};
	transitionService.onBefore(criteria, fetchParams, {priority: 1000});
}


/**
 * This hook redirects to auth.login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.requiresAuth; }
	};
	const redirectToLogin = (transition: Transition) => {
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (!authService.hasToken()) {
			console.log('No token', 'redirect to login');
			return stateService.target('auth.login', undefined, { location: false });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToLogin, {priority: 1000});
}

/**
 * This hook redirects to auth.challenge_2fa when both:
 * - The challenge is required
 * - The challenge is not yet passed
 */
export function auth2faPassed(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.auth2faPassed; }
	};
	const redirectToChallenge2fa = (transition: Transition) => {
		// console.log('auth2faPassed hook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const challenge_2fa_required = authService.extractCurrentJWTVAlue('challenge_2fa_required');
		const challenge_2fa_passed = authService.extractCurrentJWTVAlue('challenge_2fa_passed');
		if (challenge_2fa_required && !challenge_2fa_passed) {
			return stateService.target('auth.challenge_2fa', stateService.params, {reload: true, location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToChallenge2fa, {priority: 500});
}

// hook to force the fetching of the current user info before entering the substates
export function meGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'meGuard' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.meGuard; }
	};
	const currentUser = (transition: Transition) => {
		// console.log('meGuardHook');
		const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
		const currentUser = utilisateurService.currentUtilisateurValue;
		if (!!!currentUser) {
			return successOrLogout(utilisateurService.getCurrentUtilisateur(), transition);
		}
		return true;
	};
	transitionService.onBefore(criteria, currentUser, {priority: 800});
}

export function fetchPortailsHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.fetchPortails; }
	};
	const currentUser = (transition: Transition) => {
		const portailService: PortailService = transition.injector().get(PortailService);
		if (!portailService.portails.length) {
			return successOrLogout(portailService.getPortails(), transition);
		}
		return true;
	};
	transitionService.onBefore(criteria, currentUser, {priority: 500});
}

export function passwordExpiredGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.passwordExpiredGuard; }
	};
	const redirect = (transition: Transition) => {
		// console.log('passwordExpiredGuardHook');
		return transition.injector().getAsync('me') // wait for this resolve
		.then(
			(me: any) => {
				const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
				const currentUser = utilisateurService.currentUtilisateurValue;
				const stateService = transition.router.stateService;
				if (currentUser.expiration_mot_de_passe != null && currentUser.expiration_mot_de_passe <= 0) {
					console.log('Password expired', 'redirect to password change');
					return stateService.target('portail.change_password', undefined, { location: false });
				}
				return true;
			}
		);
	};
	transitionService.onBefore(criteria, redirect, {priority: 500});
}

// hook to prevent accessing auth.* states when user is already logged in
export function forbiddenWhenAuthHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'forbiddenWhenAuth' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.forbiddenWhenAuth; }
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhenAuthHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (authService.hasToken()) {
			return stateService.target('portail', undefined, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 11});
}

// hook to prevent accessing auth.challenge_2fa states when user already has a complete token
export function forbiddenWhen2faPassed(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.forbiddenWhen2faPassed; }
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhen2faPassed hook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const challenge_2fa_required = authService.extractCurrentJWTVAlue('challenge_2fa_required');
		const challenge_2fa_passed = authService.extractCurrentJWTVAlue('challenge_2fa_passed');
		if (!challenge_2fa_required || challenge_2fa_passed) {
			return stateService.target('portail', undefined, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 11});
}

// hook to prevent accessing some states when user password is not expired
export function forbiddenWhenPasswordNotExpiredHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'forbiddenWhenAuth' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.forbiddenWhenPasswordNotExpired; }
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhenPasswordNotExpiredHook');
		const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
		const currentUser = utilisateurService.currentUtilisateurValue;
		const stateService = transition.router.stateService;
		if (currentUser.expiration_mot_de_passe && currentUser.expiration_mot_de_passe > 0) {
			return stateService.target('portail', undefined, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 11});
}

// hook to force the fetching of the portailsAccessibles before entering the substates
export function portailsAccessiblesGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'portailsAccessiblesGuard' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.portailsAccessiblesGuard; }
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('portailsAccessiblesGuardHook', transition.to().name);
		const portailService: PortailService = transition.injector().get(PortailService);
		const portailsAccessibles = portailService.portailsAccessibles;
		if (portailsAccessibles.length === 0) {
			return successOrLogout(portailService.getPortailsAccessibles(), transition);
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 100});
}


// hook to prevent accessing specific portail.* states if user hasn't got the right
export function portailGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'portailGuard' property
	const criteria = {
		to: (state: StateObject) => {
			return state.parent && state.parent.name == 'portail'
				&& state.data && state.data.portailGuard;
		}
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('portailGuardHook', transition.to().name);
		const portailService: PortailService = transition.injector().get(PortailService);
		const stateService = transition.router.stateService;
		let por_code = transition.to().name;
		return transition.injector().getAsync('portails') // wait for this resolve to complete
		.then(
			(portails: any) => {
				if (typeof por_code != 'undefined') {
					por_code = por_code.split('.')[0];
					por_code = por_code.replace(/^portail-/, '');
					const canAccess = portailService.canAccessPortail(por_code);
					if (canAccess === true) {
						portailService.setPortail(por_code);
						// console.log('Can access portail', por_code);
						return true;
					}
				}
				console.log('Cannot access portail', por_code, 'go to selection');
				return stateService.target('portail', undefined, {location: true});
			}
		);
	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 50});
}

// hook to force the fetching of the contextesAccessibles before entering the substates
export function contextesAccessiblesGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'contextesAccessiblesGuard' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.contextesAccessiblesGuard; }
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('contextesAccessiblesGuardHook', transition.to().name);
		return transition.injector().getAsync('portails') // wait for this resolve to complete
		.then(
			(portails: any) => {
				const stateService = transition.router.stateService;
				const portailService: PortailService = transition.injector().get(PortailService);
				const storageService: StorageService = transition.injector().get(StorageService);
				const eventManager: EventManagerService = transition.injector().get(EventManagerService);
				const contextesAccessibles = portailService.contextesAccessibles;
				if (contextesAccessibles.length === 0) {
					return portailService.getContextesAccessibles().toPromise()
					.then(
						(response: any) => {
							const params = transition.params();
							if (
								params && params.ctx_root_id
								|| portailService.currentPortail.por_code == 'eqip'
							) {
								// the contexte was already selected
								return true;
							}
							else {
								// if we're going to one of the portail's direct child states
								if (transition.targetState().state().parent == 'portail' && response.contextes.length) {
									const last_contexte = storageService.get('last_contexte');
									let ctx_id;
									if (!last_contexte || response.contextes.length == 1) {
										// no contexte was accessed before,
										// or we've got just the one contexte available on this portail
										// go to the first contexte we've got access to
										ctx_id = response.contextes[0].ctx_id;
									}
									else {
										const found = response.contextes.find((one: any) => {return one.ctx_id == last_contexte; });
										if (found) {
											// a contexte was accessed before, and is still available
											ctx_id = found.ctx_id;
										}
										else {
											// otherwise, continue with the transition
											return true;
										}
									}
									return stateService.target(transition.targetState().name() + '.root', {ctx_root_id: ctx_id })
								}
								// otherwise, continue with the transition
								return true;
							}
						},
						(error: any) => {
							eventManager.emit('logout');
						}
					);
				}
				return true;
			}
		)

	};
	transitionService.onBefore(criteria, redirectToPortails, {priority: 40});
}


// close mobile menu when changing state
export function closeMobileMenuHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return true }
	};
	const closeMobileMenu = (transition: Transition) => {
		const appMenuService: AppMenuService = transition.injector().get(AppMenuService);
		appMenuService.toggleMobileMenu(false);
		return true;
	};
	transitionService.onEnter(criteria, closeMobileMenu, {priority: 15});
}

// send a signal to close all modals when changing state
export function closeModalsHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return true }
	};
	const closeModals = (transition: Transition) => {
		const eventManager: EventManagerService = transition.injector().get(EventManagerService);
		eventManager.emit('close-modals');
		return true;
	};
	transitionService.onFinish(criteria, closeModals, {priority: 15});
}

// update app menu active status
export function updateAppMenuActiveStatus(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return true }
	};
	const updateActiveStatus = (transition: Transition) => {
		const appMenuService: AppMenuService = transition.injector().get(AppMenuService);
		appMenuService.updateAppMenuActiveStatus();
		return true;
	};
	transitionService.onSuccess(criteria, updateActiveStatus, {priority: 15});
}

// hook to prevent the transition to a state on which the user has not enough rights
export function canAccessHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.droits; }
	};
	const stopTransition = (transition: Transition) => {
		// console.log('canAccessHook');
		const portailService: PortailService = transition.injector().get(PortailService);
		const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
		const stateService = transition.router.stateService;
		const destinationState = transition.to();
		if (portailService.checkIfHasRight(destinationState.data.droits, !!destinationState.data.requiresAllRights)) {
			// console.log('Can access', destinationState.name, destinationState.data.droits, !!destinationState.data.requiresAllRights);
			return true;
		}
		console.log('Cannot access', destinationState.name, destinationState.data.droits, !!destinationState.data.requiresAllRights)
		let parentState = stateUtilsService.getFirstNonAbstractParent(destinationState);
		console.log('Redirect to', parentState);
		let params: any = {};
		if (transition.params().ctx_root_id) {
			params.ctx_root_id = transition.params().ctx_root_id;
		}
		return stateService.target(parentState, params, {location: true});
	};
	transitionService.onBefore(criteria, stopTransition, {priority: 10});
}

export function fonctionnaliteActiveHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.fonctionnalite; }
	};
	const stopTransition = (transition: Transition) => {
		const parametreService: ParametreService = transition.injector().get(ParametreService);
		const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
		const stateService = transition.router.stateService;
		const destinationState = transition.to();
		let fonctionnaliteActive: boolean = true;
		if (typeof destinationState.data.fonctionnalite != 'undefined') {
			fonctionnaliteActive = parametreService.getParam(destinationState.data.fonctionnalite, false);
		}
		if (!fonctionnaliteActive) {
			console.log('Inactive functionnality', destinationState.name, destinationState.data.fonctionnalite);
			let parentState = stateUtilsService.getFirstNonAbstractParent(destinationState);
			console.log('Redirect to', parentState);
			let params: any = {};
			if (transition.params().ctx_root_id) {
				params.ctx_root_id = transition.params().ctx_root_id;
			}
			return stateService.target(parentState, params, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, stopTransition, {priority: 10});
}

// hook to fetch rights for the contexte
export function droitsContexteHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.droitsContexte; }
	};
	const pass = (transition: Transition) => {
		// console.log('droitsContexteHook');
		const portailService: PortailService = transition.injector().get(PortailService);
		// Set contexte if it's not already set
		if (!portailService.currentContexte) {
			if (portailService.contextesAccessibles.length == 1) {
				portailService.setContexte(portailService.contextesAccessibles[0]);
			}
			else {
				portailService.setContexteById(transition.params().ctx_root_id);
			}
		}
		// Fetch rights if not already fetched
		const droits = portailService.currentDroits;
		if (droits.length === 0) {
			return successOrRedirect(portailService.getDroitsCurrentContexte(), transition, [403])
		}
		return true;
	};
	transitionService.onBefore(criteria, pass, {priority: 20});
}

export function contextesOfCurrentGroupeHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.droitsContexte; }
	};
	const pass = (transition: Transition) => {
		// console.log('contextesOfCurrentGroupeHook');
		const portailService: PortailService = transition.injector().get(PortailService);
		// Fetch contextes if not already fetched
		const contextesOfCurrentGroupe = portailService.contextesOfCurrentGroupe;
		if (
			portailService.currentContexte.por_id != 1
			&& contextesOfCurrentGroupe.length === 0
			&& portailService.hasAccesGroupe()
		) {
			return successOrRedirect(portailService.getContextesOfCurrentGroupe(), transition);
		}
		return true;
	};
	transitionService.onEnter(criteria, pass, {priority: 10});
}

// pour récupérer les partenariats administrables par l'utilisateur sur le contexte courant
export function partenariatsAdministrablesHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.partenariatsAdministrables; }
	};
	const pass = (transition: Transition) => {
		// console.log('partenariatsAdministrablesHook');
		const parametreService: ParametreService = transition.injector().get(ParametreService);
		const portailService: PortailService = transition.injector().get(PortailService);
		// si la fonctionnalité d'admin partenariat par les adhérents
		if (parametreService.getParam('administration_partenariats_par_adherent')) {
			// Set contexte if it's not already set
			if (!portailService.currentContexte) {
				if (portailService.contextesAccessibles.length == 1) {
					portailService.setContexte(portailService.contextesAccessibles[0]);
				}
				else {
					portailService.setContexteById(transition.params().ctx_root_id);
				}
			}
			// Fetch rights if not already fetched
			const partenariatsAdministrables = portailService.partenariatsAdministrables;
			if (partenariatsAdministrables === null) {
				return successOrRedirect(portailService.getPartenariatsAdministrables(), transition);
			}
			return true;
		}
		return true;
	};
	transitionService.onBefore(criteria, pass, {priority: 10});
}

// pour checker si l'utilisateur peut administrer le partenariat
export function canAdministerPartenariat(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.canAdministerPartenariat; }
	};
	const pass = (transition: Transition) => {
		// console.log('canAdministerPartenariat');
		const portailService: PortailService = transition.injector().get(PortailService);
		const stateService = transition.router.stateService;
		if (portailService.onPortailEqip()) {
			return true;
		}
		let partenariat = transition.injector().get('partenariat');
		if (!portailService.isAdminPartenariat(partenariat)) {
			const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
			const destinationState = transition.to();
			let parentState = stateUtilsService.getFirstNonAbstractParent(destinationState);
			return stateService.target(parentState, undefined, {reload: true, location: true});
		}
		return true;
	};
	transitionService.onFinish(criteria, pass, {priority: 10});
}

// hook to reset contexte related things
export function resetContexteHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.resetContexte; }
	};
	const pass = (transition: Transition) => {
		const portailService: PortailService = transition.injector().get(PortailService);
		portailService.resetContexte();
		return true;
	};
	transitionService.onBefore(criteria, pass, {priority: 1});
}

// hook to make sure the state we're trying to reach corresponds to the current contexte
export function currentContexteHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.currentContexteGuard; }
	};
	const stopTransition = (transition: Transition) => {
		// console.log('currentContexteGuard');
		const portailService: PortailService = transition.injector().get(PortailService);
		const stateService = transition.router.stateService;
		const stateParams = transition.params();
		const currentPortail = portailService.currentPortail;
		const currentContexte = portailService.currentContexte;
		if (currentContexte && stateParams && stateParams.ctx_root_id) {
			if (currentContexte.ctx_id != stateParams.ctx_root_id) {
				console.log('Wrong contexte', `${currentContexte.ctx_id} != ${stateParams.ctx_root_id}` , 'Redirect to portail');
				return stateService.target('portail', {ctx_root_id: currentContexte.ctx_id})
			}
		}
		return true;
	};
	transitionService.onEnter(criteria, stopTransition, {priority: 50});
}
