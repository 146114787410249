import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

import { AnnexeDemandeReglement } from '@app/compta/annexe-demande-reglement.model';
import { ArticleComptable } from '@app/compta/article-comptable.model';
import { ArticleFacture } from '@app/compta/article-facture.model';
import { CodeComptableRistourne } from '@app/compta/code-comptable-ristourne.model';
import { Convention } from '@app/convention/convention.model';
import { ConventionDemandeReglement } from '@app/compta/convention-demande-reglement.model';
import { ConventionService } from '@app/convention/convention.service';
import { DemandeReglement } from '@app/compta/demande-reglement.model';
import { DistributionReglement } from '@app/compta/distribution-reglement.model';
import { EtatConventionDemandeReglement } from '@app/compta/etat-convention-demande-reglement.model';
import { EtatDemandeReglement } from '@app/compta/etat-demande-reglement.model';
import { EtatDistributionReglement } from '@app/compta/etat-distribution-reglement.model';
import { EtatFacture } from '@app/compta/etat-facture.model';
import { EvenementDemandeReglement } from '@app/compta/evenement-demande-reglement.model';
import { ExportFactureERP } from '@app/compta/export-facture-erp.model';
import { FactureConvention } from '@app/compta/facture-convention.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { ModeCalculPrestationPipe } from '@app/convention/mode-calcul-prestation.pipe';
import { ModeReglement } from '@app/compta/mode-reglement.model';
import { OperationPrestation } from '@app/compta/operation-prestation.model';
import { OrigineFacture } from '@app/compta/origine-facture.model';
import { PortailPipe } from '@app/portail/portail.pipe';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { Reglement } from '@app/compta/reglement.model';
import { SourceDemandeReglement } from '@app/compta/source-demande-reglement.model';
import { TypeFacture } from '@app/compta/type-facture.model';
import { TypeReglement } from '@app/compta/type-reglement.model';

import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import { environment } from '@environments/environment';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, arrayOfMonths, downloadFromHttpResponse } from '@helpers/utils';

export type typeExportDemande = 'dolibarr'|'publipostage'|'rappel'|'reglements';
export type EtatFactureParam =  'B'|'V'|'E'|'A';

@Injectable({providedIn: 'root'})
export class ComptaService {

	_typesReglement: TypeReglement[] = [];
	// [
	// 	{trg_code: 'A', trg_libelle: 'Demandes d\'avoir'},
	// 	{trg_code: 'F', trg_libelle: 'Factures'},
	// ];

	_modesReglement: ModeReglement[] = [];
	// [
	// 	{mrg_code: 'A', mrg_libelle: 'Autre'},
	// 	{mrg_code: 'C', mrg_libelle: 'Chèque'},
	// 	{mrg_code: 'V', mrg_libelle: 'Virement'},
	// ];

	_sourcesDemandeReglement: SourceDemandeReglement[] = [];
	// [
	// 	{sdr_code: 'B', sdr_libelle: 'BFA'},
	// 	{sdr_code: 'P', sdr_libelle: 'Prestation'},
	// ];

	_etatsDemandeReglement: EtatDemandeReglement[] = [];
	// [
	// 	{edr_code: 'E', edr_libelle: 'En cours'},
	// 	{edr_code: 'C', edr_libelle: 'Clôturée'},
	// ];

	_etatsConventionDemandeReglement: EtatConventionDemandeReglement[] = [];
	// [
	// 	{ecd_code: 'X', ecd_libelle: 'Exclusion', ecd_ordre: 4},
	// 	{ecd_code: 'B', ecd_libelle: 'Brouillon', ecd_ordre: 1},
	// 	{ecd_code: 'A', ecd_libelle: 'En attente de règlement', ecd_ordre: 2},
	// 	{ecd_code: 'S', ecd_libelle: 'Soldée', ecd_ordre: 3}
	// ];

	_etatsDistributionReglement: EtatDistributionReglement[] = [];
	// [
	// 	{edb_code: 'B', edb_libelle: 'Brouillon'},
	// 	{edb_code: 'V', edb_libelle: 'Validée'},
	// 	{edb_code: 'D', edb_libelle: 'Distribuée'},
	// ];

	_etatsFacture: EtatFacture[] = [];
	// [
	// 	{efa_code: 'A', efa_libelle: 'Annulée', efa_ordre: 4},
	// 	{efa_code: 'B', efa_libelle: 'Brouillon', efa_ordre: 1},
	// 	{efa_code: 'E', efa_libelle: 'Exportée', efa_ordre: 3},
	// 	{efa_code: 'V', efa_libelle: 'Validée', efa_ordre: 2},
	// ];

	_originesFacture: OrigineFacture[] = [];
	// [
	// 	{orf_code: 'A', orf_libelle: 'Acompte'},
	// 	{orf_code: 'M', orf_libelle: 'Manuelle'},
	// 	{orf_code: 'D', orf_libelle: 'Demande de règlements'},
	// ];

	_typesFacture: TypeFacture[] = [];
	// [
	// 	{tfa_code: 'A', tfa_libelle: 'Acompte'},
	// 	{tfa_code: 'F', tfa_libelle: 'Facture'},
	// ];


	articlesComptables: ArticleComptable[] = [];


	constructor(
		private conventionService: ConventionService,
		private datePipe: DatePipe,
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private modeCalculPrestationPipe: ModeCalculPrestationPipe,
		private portailPipe: PortailPipe,
		private portailService: PortailService,
	) {
		this._etatsConventionDemandeReglement = listesGeneriquesService.getListe('etats_conventions_demandes_reglements');
		this._etatsDemandeReglement = listesGeneriquesService.getListe('etats_demandes_reglements_fdq');
		this._etatsDistributionReglement = listesGeneriquesService.getListe('etats_distributions_reglements_fdq');
		this._etatsFacture = listesGeneriquesService.getListe('etats_factures');
		this._modesReglement = listesGeneriquesService.getListe('modes_reglements');
		this._originesFacture = listesGeneriquesService.getListe('origines_factures');
		this._sourcesDemandeReglement = listesGeneriquesService.getListe('sources_demandes_reglements');
		this._typesFacture = listesGeneriquesService.getListe('types_factures');
		this._typesReglement = listesGeneriquesService.getListe('types_reglements');
	}


	public getDummy() {
		return new DemandeReglement();
	}

	public get typesReglements() {
		return [...this._typesReglement];
	}

	public get modesReglements() {
		return [...this._modesReglement];
	}

	public get sourcesDemandeReglement() {
		return [...this._sourcesDemandeReglement];
	}

	public get etatsDemandeReglement() {
		return [...this._etatsDemandeReglement];
	}

	public get etatsConventionDemandeReglement() {
		return [...this._etatsConventionDemandeReglement];
	}

	public get etatsDistributionReglement() {
		return [...this._etatsDistributionReglement];
	}

	public get etatsFacture() {
		return [...this._etatsFacture];
	}

	public get typesFacture() {
		return [...this._typesFacture];
	}

	public get originesFacture() {
		return [...this._originesFacture];
	}

	public transformSourceDemandeCodeToLibelle(sdr_code: string, mcp_code?: string) {
		const source = this._sourcesDemandeReglement.find((one: SourceDemandeReglement) => {return one.sdr_code == sdr_code;});
		let result: string = sdr_code;
		if (source) {
			result = source.sdr_libelle;
		}
		if (mcp_code) {
			const modeCalcul: string = this.modeCalculPrestationPipe.transform(mcp_code);
			result += ` (${modeCalcul})`;
		}
		return result;
	}

	public getUrlDemandeReglement(
		drf_id: number|null,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url: string = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		url = `${url}/demandes_reglements`;
		return (!drf_id)? url : `${url}/${drf_id}`;
	}

	public getUrlConventionDemandeReglement(
		con_id: number|null,
		drf_id: number,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/conventions_partenariats`;
		return (!con_id)? url : `${url}/${con_id}`;
	}

	public getUrlAnnexeDemandeReglement(
		axd_id: number|null,
		drf_id: number,
		con_id: number,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url = this.getUrlConventionDemandeReglement(con_id, drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/annexes`;
		return (!axd_id)? url : `${url}/${axd_id}`;
	}

	public getUrlEvenementDemandeReglement(
		acd_id: number|null,
		drf_id: number,
		con_id: number|null = null,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url: string;
		if (con_id) {
			url = this.getUrlConventionDemandeReglement(con_id, drf_id, grc_id_reference, ctx_id_reference);
		}
		else {
			url = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		}
		url = `${url}/activites`;
		return (!acd_id)? url : `${url}/${acd_id}`;
	}

	public getUrlReglement(
		ref_id: number|null,
		drf_id: number,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/reglements`;
		return (!ref_id)? url : `${url}/${ref_id}`;
	}

	public getUrlLigneDemandeReglement(
		ldr_id: number|null,
		drf_id: number,
		grc_id_reference?: number|null,
		ctx_id_reference?: number|null
	) {
		let url = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/lignes`;
		return (!ldr_id)? url : `${url}/${ldr_id}`;
	}

	public getUrlDistribution(
		dis_id: number|null,
		drf_id: number|null = null,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url: string = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		if (drf_id) url = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/distributions`;
		return (!dis_id)? url : `${url}/${dis_id}`;
	}

	public getUrlConventionDistribution(
		con_id: number|null,
		dis_id: number|null,
		drf_id: number|null = null,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url = this.getUrlDistribution(dis_id, drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/conventions_partenariats`;
		return (!con_id)? url : `${url}/${con_id}`;
	}

	public getUrlFilialeDistribution(
		ctx_fil_id: number|null,
		dis_id: number|null,
		drf_id: number|null = null,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let url = this.getUrlDistribution(dis_id, drf_id, grc_id_reference, ctx_id_reference);
		url = `${url}/filiales`;
		return (!ctx_fil_id)? url : `${url}/${ctx_fil_id}`;
	}

	public getUrlImportMontantsReclames(drf_id: number, con_id: number) {
		const url: string = this.getUrlConventionDemandeReglement(con_id, drf_id);
		return `${url}/import_montants_reclames`;
	}

	public getUrlImportRepartitionReglements(dis_id: number, con_id: number) {
		const url = this.getUrlConventionDistribution(con_id, dis_id);
		return `${url}/import_repartition_reglements`;
	}

	public getUrlFactureConvention(fac_id: number|null = null, con_id: number|null = null) {
		let url = `${environment.api_url}`;
		if (con_id) url = `${url}/conventions_partenariats/${con_id}`;
		url = `${url}/factures_conventions`;
		return (fac_id == null)? url : `${url}/${fac_id}`;
	}

	public getUrlExportFactureERP(exf_id: number|null = null) {
		let url = `${environment.api_url}/exports_factures`;
		return (exf_id == null)? url : `${url}/${exf_id}`;
	}

	public getUrlArticleComptable(arc_id: number|null = null) {
		let url = `${environment.api_url}/articles_comptables`;
		return (arc_id == null)? url : `${url}/${arc_id}`;
	}

	public prepareDemandeReglementFromServer(demande: DemandeReglement) {
		let tmp = clone(demande);
		convertDateFieldsToDate(tmp, true);
		const months = arrayOfMonths(1970, 12);
		tmp.drf_mois_string = this.datePipe.transform(months[tmp.drf_mois -1], 'MMMM');
		tmp.label = this.transformSourceDemandeCodeToLibelle(tmp.sdr_code, tmp.mcp_code);
		tmp.label += ` ${this.portailPipe.transform(demande.por_id, true)} `
		tmp.label += ' pour ' + tmp.drf_mois_string;
		tmp.label += ' ' + tmp.drf_annee;
		return tmp;
	}

	public prepareDemandesReglementFromServer(demandes: DemandeReglement[]) {
		for (let i = 0; i < demandes.length ; i++) {
			demandes[i] = this.prepareDemandeReglementFromServer(demandes[i]);
		}
	}

	public prepareLignesDemandesFromServer(lignes: any) {
		lignes.forEach((one: any) => {
			one.label = this.conventionService.getConventionNicename(one, true);
			for(let prop in one) {
				if (
					prop.startsWith('ldr_montant')
					|| prop.startsWith('ldr_tva')
					|| prop.startsWith('ldi_montant')
					|| prop.startsWith('ldi_tva')
				) {
					let simplified = prop.substring(4); // on enlève le trigramme
					one[simplified] = one[prop];
				}
			}
		});
	}

	public prepareDemandeReglement(demande: DemandeReglement) {
		let tmp = clone(demande);
		if (tmp.portail) {
			tmp.por_id = this.portailService.getPortailId(tmp.portail);
			delete tmp.portail;
		}
		if (tmp.sdr_code == 'B') {
			delete tmp.types_prestations;
			delete tmp.mcp_code;
		}
		else {
			delete tmp.types_performances;
		}

		return tmp;
	}

	public getDemandeReglement(drf_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		const url: string = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url)
		.pipe(map((response: any) => {
			return this.prepareDemandeReglementFromServer(response);
		}));
	}

	public getDemandesReglement(params: any, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = clone(params);
		const url: string = this.getUrlDemandeReglement(null, grc_id_reference, ctx_id_reference);

		switch(params.regroupement) {
			case 'partenariats':
				tmpParams.regroupement = 'partenariat';
				break;
			case 'conventions_partenariats':
				tmpParams.regroupement = 'convention';
				break;
			case 'filiales':
				tmpParams.regroupement = 'filiale';
				break;
			default: // case 'demande_reglements':
				tmpParams.regroupement = 'demande';
				break;
		}

		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (params.regroupement != 'demandes_reglements') {
					response[params.regroupement].forEach((one: any) => {
						this.prepareDemandesReglementFromServer(one.demandes_reglements);
					})
				}
				else {
					this.prepareDemandesReglementFromServer(response.demandes_reglements);
				}
				return response;
			}
		));
	}

	public exportDemandesReglement(params: any, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = clone(params);
		let url: string = this.getUrlDemandeReglement(null, grc_id_reference, ctx_id_reference);
		url += '/export';

		switch(params.regroupement) {
			case 'partenariats':
				tmpParams.regroupement = 'partenariat';
				break;
			case 'conventions_partenariats':
				tmpParams.regroupement = 'convention';
				break;
			case 'filiales':
				tmpParams.regroupement = 'filiale';
				break;
			default: // case 'demande_reglements':
				tmpParams.regroupement = 'demande';
				break;
		}

		tmpParams = prepareQueryParamsForDownload(tmpParams);
		return this.http.get<any>(url, tmpParams);
	}

	public postDemandeReglement(demande: DemandeReglement) {
		let prepared = this.prepareDemandeReglement(demande);
		return this.http.post<any>(this.getUrlDemandeReglement(null), prepared);
	}

	public putDemandeReglement(demande: DemandeReglement) {
		let prepared = this.prepareDemandeReglement(demande);
		const url = this.getUrlDemandeReglement(demande.drf_id);
		return this.http.put<any>(url, prepared);
	}

	public refreshLignesBrouillonsDemandeReglement(drf_id: number) {
		const url = this.getUrlDemandeReglement(drf_id);
		return this.http.put<any>(`${url}/refresh`, null);
	}

	public deleteDemandeReglement(drf_id: number) {
		return this.http.delete<any>(this.getUrlDemandeReglement(drf_id));
	}

	public prepareReglementFromServer(reglement: Reglement) {
		let tmp = clone(reglement);
		convertDateFieldsToDate(tmp, true);
		tmp.label = this.conventionService.getConventionNicename(tmp, true)
		return tmp;
	}

	public prepareReglementsFromServer(reglements: Reglement[]) {
		for (let i = 0; i < reglements.length ; i++) {
			reglements[i] = this.prepareReglementFromServer(reglements[i]);
		}
	}

	public prepareReglement(reglement: Reglement) {
		let tmp = clone(reglement);
		convertDateFieldsToString(tmp, false, true);
		return tmp;
	}

	public getReglements(params: any, drf_id: number) {
		let tmpParams = prepareQueryParams(params);
		let url: string;
		if (drf_id) {
			url = this.getUrlReglement(null, drf_id);
		}
		else {
			url = `${environment.api_url}/reglements`;
		}
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.reglements) {
					this.prepareReglementsFromServer(response.reglements);
				}
				return response;
			}
		));
	}

	public getLignesDemandesReglement(params: any, drf_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		const url: string = this.getUrlLigneDemandeReglement(null, drf_id, grc_id_reference, ctx_id_reference);

		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.lignes_demandes_reglements) {
					this.prepareLignesDemandesFromServer(response.lignes_demandes_reglements);
				}
				return response;
			}
		));
	}

	public putLigneDemandeReglement(ligne: any) {
		const url: string = this.getUrlLigneDemandeReglement(ligne.ldr_id, ligne.drf_id);
		return this.http.put<any>(url, ligne)
	}

	public putConventionDemandeReglement(convention: ConventionDemandeReglement) {
		const url: string = this.getUrlConventionDemandeReglement(convention.con_id, convention.drf_id);
		const tmp: any = {
			drf_id: convention.drf_id,
			con_id: convention.con_id,
			drc_date_echeance: convention.drc_date_echeance,
		}
		convertDateFieldsToString(tmp, false, true);
		return this.http.put<any>(url, tmp);
	}

	public postReglement(reglement: Reglement) {
		let prepared = this.prepareReglement(reglement);
		const url: string = this.getUrlReglement(null, reglement.drf_id);
		return this.http.post<any>(url, prepared);
	}

	public putReglement(reglement: Reglement) {
		let prepared = this.prepareReglement(reglement);
		const url: string = this.getUrlReglement(reglement.ref_id, reglement.drf_id);
		return this.http.put<any>(url, prepared);
	}

	public deleteReglement(ref_id: number, drf_id: number) {
		const url: string = this.getUrlReglement(ref_id, drf_id);
		return this.http.delete<any>(url);
	}

	public getUrlImportDemandeReglement(drf_id: number) {
		const url = this.getUrlDemandeReglement(drf_id);
		return `${url}/import`;
	}

	public exportDemandeReglement(drf_id: number, type: typeExportDemande, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParamsForDownload();
		let url: string = this.getUrlDemandeReglement(drf_id, grc_id_reference, ctx_id_reference);
		switch(type) {
			case 'dolibarr':
				url += '/export_dolibarr';
				break;
			case 'publipostage':
				url += '/export_publipostage';
				break;
			case 'rappel':
				url += '/export_rappel';
				break;
			case 'reglements':
				url += '/export_reglements';
				break;
		}
		return this.http.get<any>(url, tmpParams);
	}

	public exportMontantsReclames(drf_id: number, con_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParamsForDownload();
		const url: string = this.getUrlConventionDemandeReglement(con_id, drf_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(`${url}/export_montants_reclames`, tmpParams);
	}

	public exportRepartitionReglements(dis_id: number, con_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		let url: string = this.getUrlConventionDistribution(con_id, dis_id);
		return this.http.get<any>(`${url}/export_repartition_reglements`, tmpParams);
	}

	public changerEtatDRFConvention(drf_id: number, etat: string, conventions: number[]) {
		let url: string = this.getUrlDemandeReglement(drf_id);
		url = `${url}/etat_conventions`;
		let tmpParams = {
			conventions: conventions,
			etat: etat
		}
		return this.http.post<any>(url, tmpParams);
	}

	public prepareDistributionReglementFromServer(distribution: DistributionReglement) {
		let tmp = clone(distribution);
		convertDateFieldsToDate(tmp, true);
		tmp.label = 'Distribution du ' + this.datePipe.transform(distribution.dis_date_creation, 'dd/MM/yyyy');
		return tmp;
	}

	public prepareDistributionsReglementFromServer(distributions: DistributionReglement[]) {
		for (let i = 0; i < distributions.length ; i++) {
			distributions[i] = this.prepareDistributionReglementFromServer(distributions[i]);
		}
	}

	public prepareDistributionReglement(distribution: DistributionReglement) {
		let tmp = clone(distribution);
		convertDateFieldsToString(tmp, false, true);
		return tmp;
	}

	public getDistributionReglement(dis_id:  number) {
		const url = this.getUrlDistribution(dis_id);
		return this.http.get<any>(url)
			.pipe(map((response: any) => {
				return this.prepareDistributionReglementFromServer(response);
			}));
	}

	public getDistributionsReglement(params: any, drf_id?: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		let url: string = this.getUrlDistribution(null, drf_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.distributions) {
					this.prepareDistributionsReglementFromServer(response.distributions);
				}
				return response;
			}
		));
	}

	public postDistributionReglement(drf_id: number) {
		return this.http.post<any>(this.getUrlDistribution(null, drf_id), {});
	}

	public putDistributionReglement(distribution: DistributionReglement) {
		let prepared = this.prepareDistributionReglement(distribution);
		return this.http.put<any>(this.getUrlDistribution(distribution.dis_id), prepared);
	}

	public ajouterLignesSoldeesDistributionReglement(dis_id: number) {
		const url = this.getUrlDistribution(dis_id);
		return this.http.post<any>(`${url}/refresh`, {});
	}

	public deleteDistributionReglement(dis_id: number) {
		return this.http.delete<any>(this.getUrlDistribution(dis_id))
	}

	public getFilialesDistribution(params: any, dis_id: number, ctx_id_reference?: number) {
		let tmpParams = prepareQueryParams(params);
		const url = this.getUrlFilialeDistribution(null, dis_id);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				return response;
			}
		));
	}

	public getFilialesDistributionLignes(params: any, dis_id: number, ctx_fil_id: number, ctx_id_reference?: number) {
		let tmpParams = prepareQueryParams(params);
		const url = this.getUrlFilialeDistribution(ctx_fil_id, dis_id);
		return this.http.get<any>(`${url}/lignes`, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.lignes_distributions_reglements) {
					this.prepareLignesDemandesFromServer(response.lignes_distributions_reglements);
				}
				return response;
			}
		));
	}

	public exportDistributionPublipostage(dis_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		let url: string = this.getUrlDistribution(dis_id);
		return this.http.get<any>(`${url}/export_publipostage`, tmpParams);
	}

	public exportDistributionAnnexes(dis_id: number, ctx_fil_id?: number, ctx_id_reference?: number) {
		let tmpParams = prepareQueryParamsForDownload();
		let url;
		if (ctx_fil_id) {
			url = this.getUrlFilialeDistribution(ctx_fil_id, dis_id);
		}
		else {
			url = this.getUrlDistribution(dis_id);
		}
		url = `${url}/export_annexes`;
		return this.http.get<any>(url, tmpParams);
	}


	public getConventionsOfDemandeReglement(params: any, drf_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		const url: string = this.getUrlConventionDemandeReglement(null, drf_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.conventions_partenariats) {
					this.conventionService.prepareConventionsFromServer(response.conventions_partenariats);
					response.conventions_partenariats.forEach((one: any) => {
						if (one.annexes) {
							this.prepareAnnexesDemandReglementFromServer(one.annexes);
						}
						if (one.reglements) {
							this.prepareReglementsFromServer(one.reglements);
						}
					});
				}
				return response;
			}
		));
	}

	public getConventionOfDemandeReglement(con_id: number, drf_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		const url: string = this.getUrlConventionDemandeReglement(con_id, drf_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				response = this.conventionService.prepareConventionFromServer(response);
				if (response.annexes) {
					this.prepareAnnexesDemandReglementFromServer(response.annexes);
				}
				return response;
			}
		));
	}

	public getConventionsOfDistribution(params: any, dis_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		const url: string = this.getUrlConventionDistribution(null, dis_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.conventions_partenariats) {
					this.conventionService.prepareConventionsFromServer(response.conventions_partenariats);
				}
				return response;
			}
		));
	}

	public getLignesDistributionsReglement(params: any, dis_id: number, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		const url: string = this.getUrlDistribution(dis_id, null, grc_id_reference, ctx_id_reference);

		return this.http.get<any>(`${url}/lignes`, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.lignes_distributions_reglements) {
					this.prepareLignesDemandesFromServer(response.lignes_distributions_reglements);
				}
				return response;
			}
		));
	}

	public getArticlesComptables(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(this.getUrlArticleComptable(), tmpParams)
			.pipe(map(
				(response: any) => {
					if (response.articles_comptables) {
						this.articlesComptables = response.articles_comptables;
					}
					return response;
				}
			));
	}

	public getCachedListArticlesComptables(refresh: boolean = false): Observable<ArticleComptable[]> {
		if (!refresh && !!this.articlesComptables.length) {
			return new Observable<any>(subscriber => {
				subscriber.next(this.articlesComptables);
				subscriber.complete();
			});
		}
		else {
			return this.getArticlesComptables({})
				.pipe(map(
					(response: any) => {
						return response.articles_comptables;
					})
				);
		}
	}

	public putArticleComptable(article: ArticleComptable) {
		return this.http.put<any>(this.getUrlArticleComptable(article.arc_id), article)
			.pipe(map(
				(response: any) => {
					this.articlesComptables = [];
					return response;
				}
			));
	}

	public postArticleComptable(article: ArticleComptable) {
		return this.http.post<any>(this.getUrlArticleComptable(), article)
			.pipe(map(
				(response: any) => {
					this.articlesComptables = [];
					return response;
				}
			));
	}

	public deleteArticleComptable(arc_id: number) {
		return this.http.delete<any>(this.getUrlArticleComptable(arc_id))
			.pipe(map(
				(response: any) => {
					this.articlesComptables = [];
					return response;
				}
			));
	}

	public getCodesComptablesRistournes() {
		return this.http.get<any>(`${environment.api_url}/codes_comptables_ristournes`);
	}

	public putCodeComptableRistourne(code: CodeComptableRistourne) {
		return this.http.put<any>(`${environment.api_url}/codes_comptables_ristournes/${code.ccr_id}`, code);
	}

	public prepareFacturesConventionFromServer(factures: FactureConvention[]) {
		for (let i = 0; i < factures.length ; i++) {
			factures[i] = this.prepareFactureConventionFromServer(factures[i]);
		}
	}

	public prepareFactureConventionFromServer(facture: FactureConvention) {
		let tmp = clone(facture);
		convertDateFieldsToDate(tmp, true);
		const months = arrayOfMonths(1970, 12);
		tmp.fac_mois_string = this.datePipe.transform(months[tmp.fac_mois -1], 'MMMM');
		const conventionNicename = this.conventionService.getConventionNicename((facture as unknown) as Convention, true);
		tmp.conventionNicename = conventionNicename;
		let typeService = this.transformSourceDemandeCodeToLibelle(tmp.sdr_code)
		tmp.total_ht = 0;
		tmp.articles.forEach((one: ArticleFacture) => {
			one.total_ht = one.arf_quantite * one.arf_montant_unitaire;
			tmp.total_ht += one.total_ht;
		});
		tmp.label = (tmp.total_ht < 0)? 'Avoir' : 'Facture';
		tmp.label = `${tmp.label} N°${tmp.fac_id}`;
		return tmp;
	}

	public getFacturesConventions(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(this.getUrlFactureConvention(null), tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.factures_conventions) {
					this.prepareFacturesConventionFromServer(response.factures_conventions);
				}
				return response;
			}
		));
	}

	public exportFacturesConventions(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		return this.http.get<any>(`${environment.api_url}/factures_conventions/export`, tmpParams);
	}

	public getFactureConvention(fac_id: number) {
		return this.http.get<any>(this.getUrlFactureConvention(fac_id))
		.pipe(map(
			(response: any) => {
				return this.prepareFactureConventionFromServer(response);
			}
		));
	}

	public prepareFactureConvention(facture: FactureConvention) {
		let tmp = clone(facture);
		convertDateFieldsToString(tmp, false, true);
		return tmp;
	}

	public putFactureConvention(facture: FactureConvention) {
		let tmp = this.prepareFactureConvention(facture);
		return this.http.put<any>(this.getUrlFactureConvention(facture.fac_id), tmp);
	}

	public changerEtatFacturesConventions(etatFacture: EtatFactureParam|EtatFactureParam[], fac_ids: number[]) {
		let tmpParams = {
			fac_ids: fac_ids,
			efa_code: etatFacture
		}
		const url = this.getUrlFactureConvention(null);
		return this.http.post<any>(`${url}/changer_etat`, tmpParams);
	}

	public deleteFactureConvention(fac_id: number) {
		return this.http.delete<any>(this.getUrlFactureConvention(fac_id));
	}

	public postFactureConvention(params: any, con_id?: number) {
		let tmpParams = clone(params);
		convertDateFieldsToString(tmpParams, false, true);
		return this.http.post<any>(this.getUrlFactureConvention(null, con_id), tmpParams);
	}

	public avoirFactureConvention(fac_id: number) {
		const url = this.getUrlFactureConvention(fac_id);
		return this.http.post<any>(`${url}/annuler`, {});
	}

	public exportFacturesConventionsERP(fac_ids: number[]) {
		let tmpParams: any = {
			fac_ids: fac_ids,
		};
		return this.http.post<any>(`${environment.api_url}/exports_factures_erp`, tmpParams);
	}

	public prepareExportFacturesERPFromServer(exportFacture: ExportFactureERP) {
		let tmp = clone(exportFacture);
		convertDateFieldsToDate(tmp, true);
		tmp.label = `Export N°${tmp.exf_id} du ` + this.datePipe.transform(tmp.exf_date, 'dd/MM/yyyy HH:mm');
		return tmp;
	}

	public prepareExportsFacturesERPFromServer(exportsFactures: ExportFactureERP[]) {
		for (let i = 0; i < exportsFactures.length ; i++) {
			exportsFactures[i] = this.prepareExportFacturesERPFromServer(exportsFactures[i]);
		}
	}

	public getExportsFacturesERP(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(this.getUrlExportFactureERP(null), tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.exports_factures) {
					this.prepareExportsFacturesERPFromServer(response.exports_factures);
				}
				return response;
			}
		));
	}

	public getExportFactureERP(exf_id: number, download: boolean = false) {
		const url = this.getUrlExportFactureERP(exf_id);
		if (download) {
			let tmpParams = prepareQueryParamsForDownload();
			return this.http.get<any>(`${url}/export`, tmpParams)
			.pipe(mergeMap(
				(response: any) => {
					return downloadFromHttpResponse(response);
				}
			))
		}
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareExportFacturesERPFromServer(response);
			}
		));
	}

	public deleteDernierExportFactureERP() {
		const url = this.getUrlExportFactureERP();
		return this.http.delete<any>(`${url}/annuler`)
	}


	public putAnnexeDemandeReglement(annexe: AnnexeDemandeReglement, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		const url = this.getUrlAnnexeDemandeReglement(annexe.axd_id, annexe.drf_id, annexe.con_id, grc_id_reference, ctx_id_reference);
		return this.http.put<any>(url, annexe);
	}

	public deleteAnnexeDemandeReglement(annexe: AnnexeDemandeReglement, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		const url = this.getUrlAnnexeDemandeReglement(annexe.axd_id, annexe.drf_id, annexe.con_id, grc_id_reference, ctx_id_reference);
		return this.http.delete<any>(url);
	}

	public downloadAnnexeDemandeReglement(annexe: AnnexeDemandeReglement, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParamsForDownload();
		const url = this.getUrlAnnexeDemandeReglement(annexe.axd_id, annexe.drf_id, annexe.con_id, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(`${url}/dl`, tmpParams);
	}

	public prepareAnnexesDemandReglementFromServer(annexes: AnnexeDemandeReglement[]) {
		for (let i = 0; i < annexes.length ; i++) {
			annexes[i] = this.prepareAnnexeDemandReglementFromServer(annexes[i]);
		}
	}

	public prepareAnnexeDemandReglementFromServer(annexe: AnnexeDemandeReglement) {
		let tmp = clone(annexe);
		tmp.label = this.conventionService.getConventionNicename(tmp, true);
		convertDateFieldsToDate(tmp, true);
		return tmp;
	}

	public postEvenementDemandeReglement(evenement: EvenementDemandeReglement) {
		const url = this.getUrlEvenementDemandeReglement(null, evenement.drf_id, evenement.con_id);
		return this.http.post<any>(url, evenement);
	}

	public putEvenementDemandeReglement(evenement: EvenementDemandeReglement) {
		const url = this.getUrlEvenementDemandeReglement(evenement.acd_id, evenement.drf_id, evenement.con_id);
		return this.http.put<any>(url, evenement);
	}

	public deleteEvenementDemandeReglement(evenement: EvenementDemandeReglement) {
		const url = this.getUrlEvenementDemandeReglement(evenement.acd_id, evenement.drf_id, evenement.con_id);
		return this.http.delete<any>(url);
	}

	public getEvenementsDemandeReglement(
		params: any,
		drf_id: number,
		grc_id_reference: number|null = null,
		ctx_id_reference: number|null = null
	) {
		let tmpParams = prepareQueryParams(params);
		const url = this.getUrlEvenementDemandeReglement(null, drf_id, null, grc_id_reference, ctx_id_reference);
		return this.http.get<any>(url, tmpParams)
			.pipe(map(
				(response: any) => {
					this.prepareEvenementsDemandReglementFromServer(response.activites);
					return response;
				}
			));
	}

	public prepareEvenementsDemandReglementFromServer(evenements: EvenementDemandeReglement[]) {
		for (let i = 0; i < evenements.length ; i++) {
			evenements[i] = this.prepareEvenementDemandReglementFromServer(evenements[i]);
		}
	}

	public prepareEvenementDemandReglementFromServer(evenement: EvenementDemandeReglement) {
		let tmp = clone(evenement);
		convertDateFieldsToDate(tmp, true);
		tmp.label = this.conventionService.getConventionNicename(tmp, true);
		return tmp;
	}


}
