import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { AgenceService } from '@app/agence/agence.service';


@Component({
	selector: 'type-agence-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TypeAgenceSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class TypeAgenceSelectorComponent implements ControlValueAccessor, OnInit {

	@Input() name: string;
	@Input('value') innerValue: string;
	@Input() tag_id: string;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;

	options: any[] = [];

	constructor(private agenceService: AgenceService) {

	}

	ngOnInit() {
		if (!this.placeholder) this.placeholder = 'Sélectionnez';
		let tmp = this.agenceService.getTypesAgence.map(one => {
			return { value: one.tag_id, label: one.tag_libelle };
		});

		this.options = tmp;
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: string) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [TypeAgenceSelectorComponent],
	declarations: [TypeAgenceSelectorComponent]
})
export class TypeAgenceSelectorModule { }
