import { NgModule, Component, Input, forwardRef, OnInit, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { Contexte } from '@app/contexte/contexte.model';
import { ContexteService } from '@app/contexte/contexte.service';
import { GrcModule } from '@app/groupe-contexte/groupe-contexte.service';
import { LogoDisplayModule } from '@app/logo/logo-display';
import { PortailParam } from '@app/portail/portail.service';

@Component({
	selector: 'contexte-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ContexteSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[readonly]="readonly"
			[showClear]="shouldDisplayNullOption"
			[filter]="options.length > 5"
			filterBy="ctx_libelle"
			[filterPlaceholder]="filterPlaceholder"
			optionValue="ctx_id"
			optionLabel="ctx_libelle"
			appendTo="body"
		>
			<ng-template let-item pTemplate="item">
				<div class="selector-item flex align-items-center" [ngClass]="{'inactive': item.ctx_actif === false}">
					<div class="selector-item-icon" *ngIf="showLogo && item.ctx_id">
						<logo-display [entity]="item" [listing]="true" size="sm" [placeholderIfNone]="true"></logo-display>
					</div>
					<div [ngClass]="{'pl-2': showLogo}">{{item.ctx_libelle || item.label}}</div>
				</div>
			</ng-template>
			<ng-template pTemplate="selectedItem">
				<ng-container *ngIf="_selected">
					<div class="flex align-items-center" title="{{_selected.ctx_libelle || _selected.label}}">
						<div class="selector-item-icon" *ngIf="showLogo && _selected">
							<logo-display [entity]="_selected" [listing]="true" size="sm" [placeholderIfNone]="true"></logo-display>
						</div>
						<div [ngClass]="{'pl-2': showLogo}" class="overflow-hidden white-space-nowrap text-overflow-ellipsis">{{_selected.ctx_libelle || _selected.label}}</div>
					</div>
				</ng-container>
			</ng-template>
		</p-dropdown>
	`
})
export class ContexteSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {

	@Input() name: string;
	@Input('value') innerValue: number|null;

	@Input() portail: PortailParam; // type de contextes souhaités (filiales, partenariat_fdq, partenariat_ffg)
	@Input() grc_id: number; // filtre "normal", passé en paramètre GET

	// pour récupérer les contextes liés à un groupe A par le biais des partenariats liés aux contextes du groupe A.
	// préfixera la route avec /groupes_contextes/${grc_id_reference}
	@Input() grc_id_reference: number;

	// pour récupérer les contextes liés à un contexte A par le biais des partenariats liés au contexte A.
	// préfixera la route avec /contexte/${ctx_id_reference}
	@Input() ctx_id_reference: number;

	@Input() participantModule: GrcModule;

	@Input() portailRequired: boolean = true;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() readonly: boolean;
	@Input() autoselectIfSingle: boolean;
	@Input() autoselectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() showLogo: boolean = true;
	filters: {[key: string]: any} = {};
	options: Contexte[] = [];
	initialized: boolean = false;
	shouldDisplayNullOption: boolean = false;

	_selected: any;

	constructor(private contexteService: ContexteService) {

	}

	ngOnInit() {
		this.load();
	}

	ngOnChanges(changes: any) {
		if (typeof this.activeOnly != 'undefined') {
			this.filters.ctx_actif = this.activeOnly;
		}
		this.filters.order_by = 'ctx_libelle';
		this.filters.order = 'ASC';
		if (!this.initialized) {
			this.initialized = true;
		}
		else {
			if (
				typeof changes.grc_id != 'undefined' && changes.grc_id.currentValue != changes.grc_id.previousValue
				|| typeof changes.portail != 'undefined' && changes.portail.currentValue != changes.portail.previousValue
			) {
				this.load(true);
			}
		}
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|null) {
		this.innerValue = value;
		this._selected = this.options.find((one: any) => {return one.ctx_id  == value});
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: number|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	getEntityName() {
		const found = this.options.find((one: Contexte) => { return one.ctx_id == this.innerValue; });
		if (found) {
			return found.ctx_libelle;
		}
		return null;
	}

	load(setAfterRefresh?: boolean) {
		if (this.portailRequired && !this.portail) {
			this.options = [];
			this.setInitialValue(setAfterRefresh);
		}
		else {
			this.contexteService.getList(this.filters, this.grc_id, this.portail, this.ctx_id_reference, this.grc_id_reference, this.participantModule)
			.subscribe({
				next: (response: any) => {
					let tmp = response.contextes;
					this.shouldDisplayNullOption = this.nullOption && !(tmp.length == 1 && this.autoselectIfSingle);
					this.options = tmp;
					this.setInitialValue(setAfterRefresh);
				}
			});
		}
	}

	setInitialValue(setAfterRefresh?: boolean) {
		const incoming = this.innerValue;
		if (
			(typeof this.innerValue != 'undefined' || setAfterRefresh)
			&& this.options.length
		) {
			if (this.options.findIndex(one => {return one.ctx_id == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoselectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].ctx_id;
				}
			}
		}
		if (this.autoselectFirst && !this.innerValue && this.options.length) {
			this.innerValue = this.options[0].ctx_id;
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
		this._selected = this.options.find((one: any) => {return one.ctx_id  == this.value});
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
		LogoDisplayModule,
	],
	exports: [ContexteSelectorComponent],
	declarations: [ContexteSelectorComponent]
})
export class ContexteSelectorModule { }
