import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	map,
	retry,
	retryWhen,
	takeUntil,
} from 'rxjs/operators';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { uid } from '@helpers/utils';

export const retryCount = 5;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private abortPendingHTTPRequests$: Subject<void> = new Subject<void>();
	private countConnectionLost: number = 0;

	constructor(private eventManager: EventManagerService) {

		this.eventManager.registerEvent('abort-http-request', this, (args: any) => {
				this.abortPendingRequests();
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('abort-http-request', this);
	}

	abortPendingRequests() {
		console.log('Abort pending HTTP requests');
		this.abortPendingHTTPRequests$.next();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(

			// observable used to abort the request. Careful, parallel requests would all be aborted
			takeUntil(this.abortPendingHTTPRequests$.asObservable()),

			// retry a few times if there's no network
			retryWhen((error: Observable<any>) =>
				error.pipe(
					concatMap((error: HttpErrorResponse, count: number) => {

						if (error.status == 0) {
							this.countConnectionLost++;
							// interrupted upload, don't retry, but set a custom status code and throw
							if (request.body instanceof FormData) {
								throw new HttpErrorResponse({
									status: 470,
								});
							}
							// otherwise, retry a few times
							console.log('Connection lost', this.countConnectionLost);
							if (count <= retryCount) {
								console.log('Retrying', `${count}/${retryCount}`)
								return of(error);
							}
						}
						// all retries failed, throw
						return throwError(error);
					}),
					delay(retryWaitMilliSeconds)
				)
			),

			// handle custom errors, if API returns 200 but with errors in the body
			map((response: HttpResponse<any>) => {
				// API requested that we reload the view
				if (response.status && response.status == 205) {
					location.reload();
				}
				// API returned a custom error
				if (response.body && response.body.error) {
					throw new HttpErrorResponse({
						error: response.body.error,
						headers: response.headers,
						status: response.status,
						url: response.url || undefined
					});
				}
				// custom error when the api returned a json when we were expecting a blob
				else if (response.body instanceof Blob && response.body.type === 'application/json') {
					throw new HttpErrorResponse({
						error: response.body,
						headers: response.headers,
						status: 499,
						url: response.url || undefined
					});
				}
				// all went well
				else {
					return response;
				}
			}),

			// handle the actual errors
			catchError((response: HttpErrorResponse) => {

				console.error('ErrorInterceptor', response);

				// special case of blobs
				if (
					response.error instanceof Blob
					&& response.error.type === 'application/json'
				) {
					// https://github.com/angular/angular/issues/19888
					// When request of type Blob, the error is also in Blob instead of object of the json data
					return new Promise<any>((resolve, reject) => {
						let reader = new FileReader();
						reader.onloadend = (e: Event) => {
							try {
								const errmsg = JSON.parse((<any>e.target).result);
								const readableError = new HttpErrorResponse({
									error: errmsg.error || errmsg,
									headers: response.headers,
									status: response.status,
									statusText: response.statusText,
									url: response.url || undefined
								});
								this.toastError(readableError);
								reject(readableError);
							}
							catch (e) {
								reject(response);
							}
						};
						reader.onerror = (e) => {
							reject(response);
						};
						reader.readAsText(response.error);
					});
				}

				if (response.error.error_code) {
					// redirect to contexte selection
					if (response.error.error_code == 'unauthorized_ip') {
						this.eventManager.emit('redirectToContexteSelection');
					}
				}

				this.toastError(response);

				switch(response.status) {
					case 401:
						this.eventManager.emit('logout');
						break;
					case 403:
						if (response.error && response.error.data && response.error.data.password_expired) {
							this.eventManager.emit('refreshCurrentUser');
						}
						break;
				}

				return throwError(response);
			})
		)
	}

	toastError(httpResponse: HttpErrorResponse) {
		let message = (httpResponse.error && httpResponse.error.message)? httpResponse.error.message : null;

		switch(httpResponse.status){
			case -1:
				message = message || 'Erreur de connexion réseau';
				break;

			case 0:
				message = message || 'Perte de connexion réseau';
				break;

			case 400:
				message = message || 'Requête invalide';
				break;

			case 401:
				if (httpResponse.error.message == 'Expired JWT Token') message = null;
				break;

			case 403:
				message = message || 'Action non autorisée';
				break;

			case 404:
				message = message || 'La ressource demandée n\'existe pas.';
				break;

			case 405:
				message = message || 'Méthode non autorisée';
				break;

			case 408:
				message = message || 'Le serveur a mis trop de temps à repondre';
				break;

			case 470: // interrupted upload
				message = message || 'Erreur de connexion réseau, transfert interrompu';
				break;

			case 503: // temporary error
				if (httpResponse.error.reason && httpResponse.error.reason == 'maintenance') {
					message = 'Le service est en maintenance. Désolé pour ce désagrément. Nous revenons au plus vite.';
				}
				else {
					message = message || 'Service momentanément indisponible';
				}
				break;


			default: {
				message = message || 'Erreur non répertoriée';
			}
		}

		// don't toast for these
		if (httpResponse.url && httpResponse.url.indexOf('notifications_admins_indicateur') > -1) {
			message = '';
		}

		if (message) {
			this.eventManager.emit('toast', {severity: 'error', summary: 'Erreur', detail: message});
			if (httpResponse.error.data) {
				this.toastDetails(httpResponse.error.data);
			}
		}
	}

	toastDetails(data: any) {
		if (data && data.depends) {
			this.eventManager.emit('toast', {
				severity: 'info',
				summary: 'Objet dépendant',
				detail: data.depends,
				life: 10000
			});
		}
	}

}
