import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { prepareQueryParams } from '@helpers/prepare-query-params';

import { extractFileExtension, extractBasename } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class LogoService {

	constructor(private http: HttpClient) {

	}

	ngOnDestroy(): void {

	}

	getRouteLogo(entity: any, logo_listing: boolean = false) {
		let tmp: string = '';
		if (entity.ctx_id) {
			tmp = `${environment.api_url}/contextes/${entity.ctx_id}`;
		}
		else if (entity.mar_id) {
			tmp = `${environment.api_url}/groupes_contextes/${entity.grc_id}/marques_fournisseurs/${entity.mar_id}`;
		}
		else if (entity.grc_id) {
			tmp = `${environment.api_url}/groupes_contextes/${entity.grc_id}`;
		}
		tmp += (logo_listing)? '/logo_listing' : '/logo_print';

		return tmp;
	}

	deleteLogo(entity: any, logo_listing: boolean = false) {
		let url = this.getRouteLogo(entity, logo_listing);
		return this.http.delete<any>(url);
	}

	getUrlBaseSrcLogo(entity: any, logo_listing?: boolean, attr_prefix?: string) {
		let tmp: string;
		let dir_prefix = this.getDirectoryPrefix(entity, attr_prefix);
		if (!attr_prefix) attr_prefix = dir_prefix;
		tmp = `/logo/${dir_prefix}-${entity[attr_prefix + '_id']}`;
		tmp += (logo_listing)? '/listing' : '/print';
		return tmp;
	}

	getDirectoryPrefix(entity: any, attr_prefix?: string) {
		let result = '';
		if (entity.mar_id || !!attr_prefix && attr_prefix.startsWith('mar_') ) {
			result = 'mar';
		}
		else if (entity.ctx_id || !!attr_prefix && attr_prefix.startsWith('ctx_') ) {
			result = 'ctx';
		}
		else if (entity.grc_id || !!attr_prefix && attr_prefix.startsWith('grc_') ) {
			result = 'grc';
		}
		return result;
	}

	getAttr(entity: string, logo_listing?: boolean, attr_prefix?: string) {
		if (typeof attr_prefix == 'undefined') attr_prefix = this.getDirectoryPrefix(entity);
		if (logo_listing) {
			return `${attr_prefix}_logo_listing`;
		}
		else {
			return `${attr_prefix}_logo_print`;
		}
	}

	getUrlOriginal(entity: any, logo_listing?: boolean) {
		let attr = this.getAttr(entity, logo_listing);
		let url;
		url = this.getUrlBaseSrcLogo(entity, logo_listing);
		url += `/${entity[attr]}`;
		return url;
	}

	getUrlThumb(entity: any, logo_listing: boolean, size: string, attr_prefix?: string) {
		let url = this.getUrlBaseSrcLogo(entity, logo_listing, attr_prefix);
		let attr = this.getAttr(entity, logo_listing, attr_prefix);
		let basename = extractBasename(entity[attr]);
		let fileext = extractFileExtension(entity[attr]);
		url = `${url}/thumbs/${basename}.${size}.${fileext}`;
		return url;
	}


}
