import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { ModeReglement } from '@app/compta/mode-reglement.model';

@Pipe({name: 'modeReglement'})
export class ModeReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(mrg_code?: string): string {
		const modesReglement = this.comptaService.modesReglements;
		const etat = modesReglement.find((one: ModeReglement) => {return one.mrg_code == mrg_code;});
		if (etat) {
			return  etat.mrg_libelle;
		}
		return mrg_code? mrg_code.toString() : '';
	}
}
