import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { monthFromNumber } from '@helpers/utils';

@Pipe({name: 'month'})
export class MonthPipe implements PipeTransform {

	constructor(private datePipe: DatePipe) {}

	transform(month?: number, short?: boolean): string {
		if (typeof month == 'undefined') return '';
		const format: string = short? 'MMM' : 'MMMM';
		const result = this.datePipe.transform(monthFromNumber(month), format);
		return result || '';
	}
}
